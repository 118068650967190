import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import DateInput from '../../form/components/DateInput';
import SubmitButton from '../../form/components/SubmitButton';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { NURSE_DEPLOYMENT_RESOURCE, NURSE_SUGGESTION_RESOURCE } from '../../api/resources';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { acceptNurseSuggestionSchema } from '../schema';
import { useNurseSuggestion } from '../nurseSuggestionsSlice';
import {
    updateNurseRequest,
    useNurseRequest,
    useNurseRequestsByCareNeedId,
} from '../../nurseRequests/nurseRequestsSlice';
import ContractSelect from '../../contracts/components/ContractSelect';
import LoadingBox from '../../loading/components/LoadingBox';
import { useApi } from '../../api/components/ApiProvider';
import { EMAIL_RESOURCE } from '../../api/resources';
import { selectAllAgencies } from '../../agencies/agenciesSlice';
import { selectAllEmployees } from '../../employees/employeesSlice';

const AcceptNurseSuggestionForm = ({ nurseSuggestionId, onClose, submitRef }) => {
    const dispatch = useDispatch();
    const [
        nurseSuggestion,
        { loading: nurseSuggestionLoading, initialized: nurseSuggestionInitialized },
    ] = useNurseSuggestion(nurseSuggestionId);
    const [nurseRequest, { loading: nurseRequestLoading, initialized: nurseRequestInitialized }] =
        useNurseRequest(nurseSuggestion && nurseSuggestion.nurseRequestId);
    const [
        nurseRequests,
        { loading: nurseRequestsLoading, initialized: nurseRequestsInitialized },
    ] = useNurseRequestsByCareNeedId(nurseRequest && nurseRequest.careNeedIds[0]);

    const agencies = useSelector(selectAllAgencies);
    const employees = useSelector(selectAllEmployees);

    const loading = nurseSuggestionLoading || nurseRequestLoading || nurseRequestsLoading;
    const initialized =
        nurseSuggestionInitialized && nurseRequestInitialized && nurseRequestsInitialized;

    const initialValues = useInitialValues(nurseSuggestion, {
        confirmedAt: null,
        agencyConfirmedAt: null,
        customerContractId: '',
        careFrom: null,
        careTill: null,
    });
    const api = useApi();
    const handleDeploymentSubmit = useResourceSubmit(null, NURSE_DEPLOYMENT_RESOURCE);
    const handleSuggestionSubmit = useResourceSubmit(nurseSuggestionId, NURSE_SUGGESTION_RESOURCE);

    const handleSubmit = useCallback(
        async (values) => {
            /* send a request cancelation message to all other agencies
            await Promise.all(
                nurseRequests.map((request) => {
                    if (request.id !== nurseRequest.id) {
                        //console.log("request =>",request);
                        const agency = agencies.find(
                            (agency) => agency.id === nurseRequest.agencyId
                        );
                        if (agency == null) {
                            console.error('Invalid agencyId', request.agencyId);
                            return true;
                        }
                        const email_text = `Liebe Agentur ${agency.name}, <br /> <br />\
unser Kunde hat sich soeben für einen Personalvorschlag entschieden. Für diesen Kunden benötigen wir von Euch daher keinen Vorschlag mehr. <br /> <br />\
Sollte uns Eurerseits bereits ein Personalvorschlag vorliegen, gebt uns bitte Bescheid, ob die Betreuungskraft auch für einen anderen Kunden in Frage kommt, dann stellen wir sie dort sehr gerne vor. <br />\
Wir danken Euch für Eure Unterstützung. <br /> <br />\
*** Dies ist eine automatisch generierte E-Mail und dient zur schnelleren Kommunikation. ***! <br /> <br />`;

                        let agency_email = null;
                        const defaultAgencyEmployee = employees.find((e) => {
                            return (
                                String(e.agencyId) === String(request.agencyId) &&
                                e.isPrimaryContact
                            );
                        });

                        if (defaultAgencyEmployee) {
                            agency_email = defaultAgencyEmployee.email;
                        }

                        if (agency_email === null) {
                            console.error(
                                'no legal agency email address found for agencyId',
                                request.agencyId
                            );
                            return true;
                        }

                        const preppedValues = {
                            from: 'kundenbetreuung@pzh.seskion.de',
                            subject: `#${request.customerId} Vielen Dank für Eure Unterstützung`,
                            text: email_text,
                            receiver: [agency_email],
                            attachments: [],
                            customer_id: request.customerId,
                            is_premium: false,
                        };
                        //console.log("send request cancelation email => request.id / preppedValues =>",request.id, preppedValues);
                        api[EMAIL_RESOURCE].sendEmail(preppedValues);
                        return true;
                    }
                    return true;
                })
            );
            */

            const { data: suggestion } = await handleSuggestionSubmit(values);

            /* create deployment */
            const { data: deployment } = await handleDeploymentSubmit({
                careNeedId: nurseRequest.careNeedIds[0],
                customerId: nurseRequest.customerId,
                customerContractId: values.customerContractId,
                agencyId: nurseRequest.agencyId,
                type: 'nurse',
                nurseId: suggestion.nurseId,
                nurseSuggestionId: suggestion.id,
                careFrom: values.careFrom,
                careTill: values.careTill,
                notes: suggestion.notes,
                // arrivalAt: null,
                // departureAt: null,
                withoutArrTransfer: false,
                withoutDepTransfer: false,
                // a1From: null,
                // a1Till: null,
                billingFrom: values.careFrom,
                // billingFromHalf: false,
                billingTill: values.careTill,
                // billingTillHalf: false,
                priceAgency: suggestion.priceAgency,
                // priceAgencyDaily: false,
                surchargeAgency: suggestion.surchargeAgency,
                // a1Notes: '',
                provisionPZH: suggestion.provisionPZH,
                surchargePZH: suggestion.surchargePZH,
                provisionLocation: suggestion.provisionLocation,
                provisionMultiplier: '', // FIXME: missing in form
            });

            /* close nurse requests */
            await Promise.all(
                nurseRequests.map((nurseRequest) =>
                    dispatch(
                        updateNurseRequest({
                            id: nurseRequest.id,
                            closedAt: nurseRequest.closedAt || new Date(),
                        })
                    )
                )
            );
            const result = { suggestion, deployment };

            if (onClose) {
                return onClose(result);
            }
            return result;
        },
        [
            handleDeploymentSubmit,
            handleSuggestionSubmit,
            onClose,
            nurseRequest,
            nurseRequests,
            dispatch,
            agencies,
            api,
            employees,
        ]
    );

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Form
                initialValues={initialValues}
                validationSchema={acceptNurseSuggestionSchema}
                onSubmit={handleSubmit}
                subject={nurseSuggestion || NURSE_SUGGESTION_RESOURCE}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateInput name="careFrom" label="Betreuung geplant von" fullWidth />
                    </Grid>

                    <Grid item xs={6}>
                        <DateInput name="careTill" label="Betreuung geplant bis" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput name="confirmedAt" label="Kunde bestätigt" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput name="agencyConfirmedAt" label="Agentur bestätigt" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <ContractSelect
                            label="Vertrag"
                            name="customerContractId"
                            customerId={nurseRequest && nurseRequest.customerId}
                            filter={(contract) =>
                                nurseRequest && contract.agencyId === nurseRequest.agencyId
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <SubmitButton container={submitRef} />
            </Form>
        </LoadingBox>
    );
};

AcceptNurseSuggestionForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseSuggestionId: IdPropType.isRequired,
    submitRef: RefPropType,
};

AcceptNurseSuggestionForm.defaultProps = {
    submitRef: null,
};

export default AcceptNurseSuggestionForm;
