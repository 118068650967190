import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { NURSE_REQUEST_CANCELATION_ENTITY } from '../../../mailTemplates/entities';
import { PrefillMailPropType } from '../../../mails/proptypes';
import { destroyNurseRequest } from '../../../nurseRequests/nurseRequestsSlice';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { NurseRequestDataPropType } from '../../../../proptypes/careneeds';

const SendNurseRequestCancelationButton = ({
    name,
    disabled,
    nurseRequestData,
    onSent,
    subject,
    action,
    className,
    variant,
    size,
    label,
    fullWidth,
    prefill,
    color,
    ellipsis,
}) => {
    const customerId = nurseRequestData?.customer.id;
    const careNeedId = nurseRequestData?.id;
    const agencyIds = nurseRequestData?.nurseRequests.map((nurseRequest) => nurseRequest.agency);

    const dispatch = useDispatch();

    const prepareExtraEntities = useCallback(() => {
        return { agencyIds, careNeedId, customerId };
    }, [agencyIds, careNeedId, customerId]);

    //console.log("SendNurseRequestCancelationButton nurseRequestData =>",customerId, careNeedId, nurseRequestIds,"agencyIds=>", agencyIds);
    const extraFields = null; //useMemo(() => null);

    const handleSent = useCallback(
        async ({ mail, extraEntities }) => {
            //console.log('handleSent extraEntities=>', extraEntities);
            // remove all open nurse requests from this customer
            const results = await Promise.all(
                agencyIds.map((agencyId) => {
                    //console.log("dispath => destroyNurseRequest",careNeedId, customerId, agencyId)
                    dispatch(
                        destroyNurseRequest({
                            careNeedIds: [extraEntities.careNeedId],
                            customerId,
                            agencyId,
                        })
                    );
                    return true;
                })
            );

            if (onSent) {
                await onSent(results);
            }
            return true;
        },
        [customerId, dispatch, onSent, agencyIds]
    );

    return (
        <SendMailButton
            name={name}
            color={color}
            variant={variant}
            startIcon={<DeleteForeverIcon />}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={NURSE_REQUEST_CANCELATION_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            extraEntities={prepareExtraEntities}
            extraFields={extraFields}
            className={className}
            size={size}
            label={label || 'Alle BK-Anfragen absagen'}
            fullWidth={fullWidth}
            prefill={prefill}
            ellipsis={ellipsis}
        />
    );
};

SendNurseRequestCancelationButton.propTypes = {
    name: PropTypes.string,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.node,
    fullWidth: PropTypes.bool,
    prefill: PrefillMailPropType,
    ellipsis: PropTypes.bool,
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func,
    nurseRequestData: NurseRequestDataPropType.isRequired,
};

SendNurseRequestCancelationButton.defaultProps = {
    name: null,
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    label: null,
    fullWidth: false,
    prefill: null,
    ellipsis: false,
    confirmLabel: null,
    nurseRequestData: null,
};

export default SendNurseRequestCancelationButton;
